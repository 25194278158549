import type { SidebarNavLinkProps } from '../components/SidebarNav/Link';

import React, { useContext, useMemo } from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import type { ProjectContextValue, UserContextValue } from '@core/context';
import { ProjectContext, UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useDashLinkUrl from '@core/hooks/useDashLinkUrl';
import useProjectPlan from '@core/hooks/useProjectPlan';
import { useSuperHubStore } from '@core/store';

import BasePanel from '@routes/SuperHub/components/BasePanel';
import { SidebarNav, SidebarNavSection, SidebarNavLink } from '@routes/SuperHub/Settings/components';
import { SuperHubHashRoutePaths, type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import GitConnection from '../forms/GitConnection';
import ProjectSettingsForm from '../forms/Project';
import CustomLogin from '../forms/Project/CustomLogin';
import HealthCheck from '../forms/Project/HealthCheck';
import Integrations from '../forms/Project/Integrations';
import ProjectSettings from '../forms/Project/ProjectSettings';

import styles from './index.module.scss';

function Configure() {
  const bem = useClassy(styles, 'Configure');

  const { pathname } = useLocation();
  const { planFeatures } = useProjectPlan();
  const { isGod } = useContext(UserContext) as unknown as UserContextValue;
  const { project } = useContext(ProjectContext) as ProjectContextValue;

  const match = matchPath<SuperHubHashRouteParams>(pathname, Object.values(SuperHubHashRoutePaths));
  const action = match?.params.action;
  const isEnterpriseChild = useSuperHubStore(s => s.enterprise?.isChild);
  const isChildManagedBidi = isEnterpriseChild && project?.parent?.flags?.childManagedBidi;

  const dashLinkUrl = useDashLinkUrl();
  const configureNavData = useMemo(
    () =>
      [
        {
          section: 'Project',
          items: [
            {
              icon: 'book-open',
              label: 'Project Settings',
              to: '/configure/project-settings',
            },
            {
              icon: 'pie-chart',
              label: 'Documentation Metrics',
              href: dashLinkUrl('metrics/page-views'),
              target: '_blank',
            },
            {
              icon: 'alert-triangle',
              label: 'Error Pages',
              href: isEnterpriseChild ? dashLinkUrl('errorpages', { toEnterpriseGroup: true }) : dashLinkUrl('errors'),
              planFeature: 'errors',
              target: '_blank',
            },
            {
              icon: 'radio',
              label: 'Health Check',
              to: '/configure/health-check',
              planFeature: 'healthCheck',
            },
          ],
        },
        {
          section: 'Access',
          items: [
            {
              icon: 'key',
              label: 'API Keys',
              href: dashLinkUrl('api-key'),
              target: '_blank',
            },
            {
              icon: 'teammates',
              label: 'Manage Team',
              href: isEnterpriseChild ? dashLinkUrl('team', { toEnterpriseGroup: true }) : dashLinkUrl('users'),
              target: '_blank',
            },
            {
              icon: 'unlock',
              label: 'Custom Login',
              ...(isEnterpriseChild
                ? { href: dashLinkUrl('end-users/login', { toEnterpriseGroup: true }), target: '_blank' }
                : { to: '/configure/custom-login' }),
            },
            ...(isGod
              ? [
                  {
                    icon: 'lock',
                    label: 'SAML Authentication',
                    href: dashLinkUrl('saml'),
                    target: '_blank',
                  } satisfies SidebarNavLinkProps,
                ]
              : []),
          ],
        },
        {
          section: 'Admin',
          items: [
            {
              icon: 'credit-card',
              label: 'Manage Plan',
              href: dashLinkUrl('plans'),
              target: '_blank',
            },
            {
              icon: 'globe',
              label: 'Custom Domain',
              href: dashLinkUrl('domains', { toEnterpriseGroup: isEnterpriseChild }),
              target: '_blank',
              planFeature: 'custom_domain',
            },
            {
              icon: 'minimize-2',
              label: 'Integrations',
              to: '/configure/integrations',
            },
            {
              icon: 'labs',
              label: 'Labs',
              href: dashLinkUrl('labs'),
              target: '_blank',
            },
            ...(!isEnterpriseChild || isChildManagedBidi
              ? [
                  {
                    icon: 'git-branch',
                    isBeta: true,
                    label: 'Git Connection',
                    to: '/configure/git-connection',
                  },
                ]
              : []),
          ],
        },
      ] satisfies { items: SidebarNavLinkProps[]; section: string }[],
    [dashLinkUrl, isEnterpriseChild, isGod, isChildManagedBidi],
  );

  return (
    <BasePanel label="Project settings controls" safeStyles={false}>
      <div className={bem('-layout')} data-testid="configure-panel">
        <SidebarNav className={bem('-sidebar')}>
          {action === 'configure' && (
            <>
              {configureNavData.map(({ section, items }) => (
                <SidebarNavSection key={section} label={section}>
                  {items.map((props: SidebarNavLinkProps) => (
                    <SidebarNavLink key={`configure-${section}-${props.label}`} {...props} />
                  ))}
                </SidebarNavSection>
              ))}
            </>
          )}
        </SidebarNav>

        {/* Routes to render sub-forms, grouped by sections in alphabetical order for readability */}
        <Switch>
          <Route path="/:action(configure)/:section(custom-login)">
            <ProjectSettingsForm>
              <CustomLogin />
            </ProjectSettingsForm>
          </Route>
          <Route path="/configure/git-connection">
            <GitConnection />
          </Route>
          <Route path="/:action(configure)/:section(health-check)">
            <ProjectSettingsForm formGate={planFeatures.healthCheck ? 'none' : 'plan'}>
              <HealthCheck />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(integrations)">
            <ProjectSettingsForm>
              <Integrations />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(project-settings)">
            <ProjectSettingsForm>
              <ProjectSettings />
            </ProjectSettingsForm>
          </Route>
          <Route exact path="/:action(configure)">
            <Redirect to="/configure/project-settings" />
          </Route>
        </Switch>
      </div>
    </BasePanel>
  );
}

export default Configure;
